import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

class TextareaValidator extends ValidatorComponent<any, any> {
  render() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

    return (
      <div>
        <textarea
          {...rest}
          ref={r => {
            this.input = r;
          }}
        />
        {this.errorText()}
      </div>
    );
  }

  getValidState() {
    return this.state.isValid;
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <div style={{ color: 'red', fontSize: 14 }}>{this.getErrorMessage()}</div>;
  }
}

export default TextareaValidator;
