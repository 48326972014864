import React, { Component } from 'react';
import { IDocumentContent } from '../types/IDocumentContent';

interface ContactUsSubheaderProps {
  content: IDocumentContent;
  templates: Array<{
    template: {
      uid: string;
      slug: string;
    };
  }>;
}

/**
 * spit out classnames
 */
const flattenTemplatesToClassName = (
  templates: Array<{
    template: {
      uid: string;
      slug: string;
    };
  }>
) => {
  return templates.map((template) => template.template.uid).join(' ');
};

export default class ContactUsSubheader extends Component<any> {
  constructor(props: ContactUsSubheaderProps) {
    super(props);
  }

  render() {
    const { content, templates } = this.props as ContactUsSubheaderProps;
    const { data } = content.document;
    return (
      <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--8 remove-padding">
        <div className={flattenTemplatesToClassName(templates)}>
          <div className="fx-l-contact-us-subheader-content">
            {data?.title?.text && <h3 className="fx-l-contact-us-subheader-title">{data.title.text}</h3>}
            {data?.images?.[0]?.image?.url && (
              <img
                className="fx-l-contact-us-subheader-icon"
                src={data?.images?.[0]?.image?.url}
                alt={data?.title?.text}
              />
            )}
            {data?.subtitle?.text && <h4 className="fx-l-contact-us-subheader-subtitle">{data.subtitle.text}</h4>}
            {data?.body?.text && <p className="fx-l-contact-us-subheader-body">{data?.body?.text}</p>}
            {data?.cta_link && (
              <div className="fx-l-contact-us-subheader-cta">
                <a className="fx-l-section-cta fx-btn fx-btn--primary-outlined" href={data?.cta_link?.text}>
                  {data?.cta_title?.text}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
