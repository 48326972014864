export const mergePreviewData = (existingPageContext: any, previewData: any) => {
  const { prismicContent } = previewData;
  if (prismicContent && prismicContent.type === 'content' || prismicContent.type === 'templates') {
    const contentPanelItems = existingPageContext?.contentPanels[0]?.items;
    const newContentPanels =
      contentPanelItems &&
      contentPanelItems.map((panel: any) => {
        if (panel.content.document.uid === prismicContent.uid) {
          return {
            content: {
              document: {
                ...panel.content.document,
                data: prismicContent.data,
              },
            },
          };
        } else {
          return panel;
        }
      });
    return {
      ...existingPageContext,
      contentPanels: [
        {
          ...existingPageContext.contentPanels[0],
          items: newContentPanels,
        },
      ],
    };
  }
  return existingPageContext;
};