import * as React from 'react';
import { ChangeEvent, Component, MouseEvent } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import { ContactService, IContactForm } from '../../services/contact-service';
import TextValidator from './text-validator';
import TextareaValidator from './textarea-validator';
import { Link } from 'gatsby';
import { fbq } from '@hutsoninc/gatsby-plugin-facebook-pixel';

// tslint:disable-next-line:no-var-requires
const es6Promise = require('es6-promise').polyfill();
// tslint:disable-next-line:no-var-requires
const isomorphicFetch = require('isomorphic-fetch');

export interface FormState extends IContactForm {
  formSubmitted: boolean;
  fullName: string;
  subject: string;
  email: string;
  message: string;
  endpoint: string;
}

export default class FXGeneralContactForm extends Component<any, Partial<FormState>> {
  static defaultProps = {};

  private contactService: ContactService;

  constructor(props: any) {
    super(props);

    this.state = {
      fullName: '',
      subject: '',
      email: '',
      message: '',
      formSubmitted: false,
    };

    this.contactService = new ContactService(process.env.FORM_ENDPOINT);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  async submitForm(_event: MouseEvent<HTMLButtonElement>) {
    fbq('track', 'Contact');
    const form = {
      fullName: this.state.fullName,
      subject: this.state.subject,
      email: this.state.email,
      message: this.state.message,
    };
    await this.contactService.submitForm(form);
    this.setState({ formSubmitted: true });
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' && target instanceof HTMLInputElement ? target.checked : target.value;

    this.setState({ [name]: value });
  };

  render() {
    const isSDTR = process.env.TENANT === 'sdtr';
    return (
      <div className="fx-contact-form-page-wrap">
        {this.state.formSubmitted && (
          <div className="fx-contact-form-page-wrap-sent">
            <div className="fx-contact-form-title">Successfully submitted</div>
          </div>
        )}
        {!this.state.formSubmitted && (
          <div>
            <ValidatorForm
              id={`${process.env.TENANT}-form`}
              className="fx-form"
              data-ref="form"
              instantValidate={false}
              onSubmit={this.submitForm}
            >
              <div className=" fx-l-contact-us-form">
                <div className="fx-form-group fx-l-contact-us-form-group fx-l-contact-us-form-group-name">
                  <label className="fx-form-label fx-l-contact-us-form-label">Full name</label>
                  <TextValidator
                    onChange={this.handleInputChange}
                    className="fx-form-input"
                    id="fullName"
                    name="fullName"
                    placeholder="John Doe"
                    value={this.state.fullName}
                    type="text"
                    validators={['required']}
                    errorMessages={['this field is required']}
                  />
                </div>

                <div className="fx-form-group fx-l-contact-us-form-group fx-l-contact-us-form-group-email">
                  <label className="fx-form-label fx-l-contact-us-form-label">Email</label>
                  <TextValidator
                    onChange={this.handleInputChange}
                    className="fx-form-input"
                    id="email"
                    name="email"
                    placeholder="John.doe@gmail.com"
                    value={this.state.email}
                    type="text"
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                  />
                </div>
              </div>

              <div className="fx-form-group fx-l-contact-us-form-group">
                <label className="fx-form-label fx-l-contact-us-form-label">Subject</label>
                <TextValidator
                  onChange={this.handleInputChange}
                  className="fx-form-input"
                  id="subject"
                  name="subject"
                  placeholder={`${isSDTR ? 'eg. Tax return help' : 'eg. Help with bookkeeping'}`}
                  value={this.state.subject}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </div>

              <div className="fx-form-group fx-l-contact-us-form-group">
                <label className="fx-form-label fx-l-contact-us-form-label">Message</label>
                <TextareaValidator
                  onChange={this.handleInputChange}
                  className="fx-form-textarea fx-l-contact-us-form-textarea"
                  id="message"
                  name="message"
                  placeholder="Type your message here"
                  value={this.state.message}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </div>

              {isSDTR && (
                <div className="fx-l-contact-us-form-disclaimer">
                  <p>
                    By sending this message, you agree that any personal information you provide us will be used in
                    accordance with our
                    <Link to="/privacy-policy"> Privacy Policy.</Link>
                  </p>
                </div>
              )}

              <div className="fx-l-contact-us-form-submit">
                <button className="fx-btn fx-btn--primary-outlined fx-btn--full-width" type="submit">
                  Send
                </button>
              </div>
            </ValidatorForm>
          </div>
        )}
      </div>
    );
  }
}
