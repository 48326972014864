import { graphql } from 'gatsby';
import ContactUsSubheader from '../components/contact-us-subheader';
import React from 'react';
import { Helmet } from 'react-helmet';
import FxLayout from '../components/shared/layout/layout';
import uiService from '../components/shared/ui-service';
import FXGeneralContactForm from '../components/general-contact-form/index';
import { mergePreviewData } from '../services/merge-preview-data';
import { FxAccordion } from '../components/shared/layout/fx-accordion';

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        tagline
        siteUrl
      }
    }
  }
`;

export default class ContactUsPage extends React.Component<any, any> {
  uiService = uiService;
  constructor(props: any) {
    super(props);

    this.state = {
      previewPageContext: undefined,
    };
  }

  componentDidMount() {
    const IS_BROWSER = typeof window !== 'undefined';
    const staticData = this.props.pageContext;
    if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
      this.setState({ previewPageContext: mergePreviewData(staticData, window.__PRISMIC_PREVIEW_DATA__) });
    }
  }

  render() {
    const pageContext = this.state.previewPageContext || this.props.pageContext;
    const seoMeta = pageContext.seoMeta && pageContext.seoMeta.primary;
    const contentPanels = pageContext.contentPanels || [];

    let pageBG;

    if (pageContext.page && pageContext.page.data.background_image) {
      pageBG = {
        backgroundImage: `url(${pageContext.page.data.background_image.url || ''})`,
      };
    }

    return (
      <FxLayout sitemap={pageContext.sitemap}>
        {
          <Helmet>
            <title>
              {(seoMeta && seoMeta.seo_title.text) +
                ' | ' +
                (this.props.data.site.siteMetadata && this.props.data.site.siteMetadata.name)}
            </title>
            ] <meta name="keywords" content={seoMeta && seoMeta.seo_keywords.text} />
            <meta name="description" content={seoMeta && seoMeta.seo_description.text} />
            <link
              type="canonical"
              href={`${this.props.data.site.siteMetadata.siteUrl}${pageContext.page && pageContext.page.url}`}
            />
          </Helmet>
        }
        <div
          className="fx-l-contact-us"
          style={{
            backgroundImage: pageBG && pageBG.backgroundImage ? pageBG.backgroundImage : '',
          }}
        >
          <div className="fx-l-hero fx-l-contact-us-hero" key={`${new Date().getMilliseconds()}-${Math.random()}`}>
            <div className="fx-l-hero-content fx-l-contact-us-hero-content">
              <div className="fx-container">
                <h1 className="fx-l-hero-title fx-l-contact-us-hero-title">
                  {pageContext.page && pageContext.page.data.title.text}
                </h1>
                <p className="fx-l-hero-body fx-l-contact-us-hero-body">
                  {pageContext.page && pageContext.page.data.subtitle.text}
                </p>
              </div>
            </div>
          </div>

          <section className="fx-l-contact-us-section">
            <div className="fx-container">
              <div className="fx-l-contact-us-layout">
                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                  {contentPanels.map((panel: any, primaryIndex: number) => {
                    if (panel.type === 'contentPanel') {
                      return panel.items.map((item: any, secondaryIndex: number) => {
                        if (item) {
                          const templates = item.content.document && item.content.document.data.templates;

                          const { template: contactUsSubheaderTemplate } = templates.find((template: any) => {
                            return template.template && template.template.slug === 'contact-us-subheader';
                          });
                          if (contactUsSubheaderTemplate) {
                            return (
                              <ContactUsSubheader
                                key={primaryIndex + '-' + secondaryIndex}
                                content={item.content}
                                templates={templates}
                              />
                            );
                          }
                        }
                        return null;
                      });
                    }
                  })}
                </div>

                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                  <h3 className="fx-l-contact-us-office-locations-header">Send us a message</h3>
                  <FXGeneralContactForm
                    serviceLines={''}
                    industries={''}
                    adviserInfo={''}
                    contactFormReferrer={''}
                    title={''}
                  />
                </div>
                <div className="fx-l-contact-us-faqs" id="faqs">
                  {contentPanels.map((panel: any) => {
                    if (panel.type === 'collection') {
                      return (
                        <div
                          id="fx-l-faq"
                          className={`fx-l-collection  
                        ${panel &&
                            panel.items &&
                            panel.items.slice(0, 1).map((item: any) => {
                              return item.templates
                                ? item.templates.map((template: any) =>
                                  template.template && template.template.uid.includes('--secondary')
                                    ? ' fx-l-section--secondary'
                                    : ''
                                )
                                : item.data.templates.map((template: any) =>
                                  template.template && template.template.uid.includes('--secondary')
                                    ? ' fx-l-section--secondary'
                                    : ''
                                );
                            })
                            }
                        `}
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                        >
                          <div className="fx-container">
                            <div className="fx-layout">
                              {panel &&
                                panel.items.map((item: any) => {
                                  const templates = item.data.templates;

                                  if (
                                    templates.find(
                                      (template: any) => template.template && template.template.uid === 'faqs'
                                    )
                                  ) {
                                    return (
                                      <FxAccordion
                                        header={item.data.title.text}
                                        iconClassNames={'fx-accordion-toggle-icon las la-angle-down'}
                                        hasGutters={true}
                                      >
                                        <div dangerouslySetInnerHTML={{ __html: item.data && item.data.body.html }} />
                                      </FxAccordion>
                                    );
                                  }
                                  return null;
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </FxLayout>
    );
  }
}
