// tslint:disable-next-line:no-var-requires
const es6Promise = require('es6-promise').polyfill();
// tslint:disable-next-line:no-var-requires
const isomorphicFetch = require('isomorphic-fetch');

const fieldConfig: { [key: string]: { [key: string]: string | undefined } } = {
  fieldNames: {
    fullName: process.env.FORM_FULLNAME,
    subject: process.env.FORM_SUBJECT,
    email: process.env.FORM_EMAIL,
    message: process.env.FORM_MESSAGE    
  },
  defaultValues: {
    fullName: process.env.FORM_FULLNAME,
    subject: process.env.FORM_SUBJECT,
    email: process.env.FORM_EMAIL,
    message: process.env.FORM_MESSAGE
  }
};

export interface IContactForm {
  fullName: string;
  email: string;
  subject: string;
  message: string;
}

export class ContactService {
  private endpoint: string;

  constructor(endpoint?: string) {
    if (!endpoint) {
      throw new Error('Config error, contact endpoint not configured');
    }
    this.endpoint = endpoint;
  }

  submitForm(form: any) {
    const postData = JSON.stringify(form);

    return fetch(this.endpoint, {
      method: 'post',
      body: postData
    }).then((response: any) => {
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }
      return response.json();
    });
  }
}
