import React, { useState, ReactNode } from 'react';

interface IAccordionProps {
  additionalContentClassNames?: string;
  additionalContainerClassNames?: string;
  additionalButtonClassNames?: string;
  header?: ReactNode;
  iconClassNames?: string;
  disabled?: boolean;
  hasGutters?: boolean;
}

const toggle = (setVisible: any, visible: boolean) => {
  return () => {
    setVisible(!visible);
  };
};

const fxAccordion: React.FC<IAccordionProps> = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className={`fx-accordion ${props.additionalContainerClassNames || 'fx-layout-col fx-layout-col--mobile-24'}`}>
      <button
        className={`fx-accordion-toggle ${visible ? 'fx-accordion-toggle--is-visible' : ''} ${
          props.disabled ? 'fx-accordion-toggle--disabled' : ''
        } ${props.hasGutters ? 'fx-accordion-toggle--has-gutters' : ''} ${props.additionalButtonClassNames || ''}`}
        type="button"
        onClick={toggle(setVisible, visible)}
      >
        {props.header}
        <i className={`fx-accordion-toggle-icon ${props.iconClassNames || 'las la-angle-down'}`} />
      </button>
      <div
        className={`fx-accordion-content ${visible ? 'fx-accordion-content--is-visible' : ''} ${
          props.disabled ? 'fx-accordion-content--disabled' : ''
        } ${props.hasGutters ? 'fx-accordion-toggle--has-gutters' : ''} ${props.additionalContentClassNames}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export { fxAccordion as FxAccordion };
